import { lazy } from 'react';
import {
  CarOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
  TruckFilled,
  UserOutlined,
  UsergroupAddOutlined,
  FileTextOutlined,
  TagsOutlined,
  BankOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileOutlined,
  BuildOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import PermissionEnum from '../constants/PermissionEnum';

const Login = lazy(() => import('../pages/Auth/Login/Login'));
const Home = lazy(() => import('../pages/Content/Home/Home'));
const Organizations = lazy(() => import('../pages/Content/Organizations/Organizations'));
const OrganizationDetails = lazy(() => import('../pages/Content/Organizations/OrganizationDetails'));
const Services = lazy(() => import('../pages/Content/GlobalConfiguration/Services/Services'));
const Categories = lazy(() => import('../pages/Content/GlobalConfiguration/Categories/Categories'));
const Currencies = lazy(() => import('../pages/Content/GlobalConfiguration/Currencies/Currencies'));
const Locations = lazy(() => import('../pages/Content/GlobalConfiguration/Locations/Locations'));
const LoadTypes = lazy(() => import('../pages/Content/GlobalConfiguration/LoadTypes/index'));
const Tags = lazy(() => import('../pages/Content/GlobalConfiguration/Tags/Tags'));
const SinglesTrips = lazy(() => import('../pages/Content/JobOrders/SingleTrip/SingleTripJobOrders'));
const MultiTrips = lazy(() => import('../pages/Content/JobOrders/MultiTrip/MultiTripJobOrders'));
const DispatchOrders = lazy(() => import('../pages/Content/DispatchManagement/DispatchOrders/DispatchOrders'));
const Departments = lazy(() => import('../pages/Content/EmployeeConfigurations/Departments/Departments'));
const Employees = lazy(() => import('../pages/Content/EmployeeConfigurations/Employees/Employees'));
const EmployeeDetails = lazy(() => import('../pages/Content/EmployeeConfigurations/Employees/EmployeeDetails/EmployeeDetails'));
const Designations = lazy(() => import('../pages/Content/EmployeeConfigurations/Designations/Designations'));
const EmployeeDocumentTypes = lazy(() => import('../pages/Content/EmployeeConfigurations/DocumentType/DocumentType'));
const FleetStatuses = lazy(() => import('../pages/Content/FleetConfigurations/Statuses/Statuses'));
const FleetDocumentTypes = lazy(() => import('../pages/Content/FleetConfigurations/DocumentType/DocumentTypes'));
const FleetTypes = lazy(() => import('../pages/Content/FleetConfigurations/FleetType/FleetType'));
const Trailers = lazy(() => import('../pages/Content/FleetConfigurations/Trailers/index'));
const Hubs = lazy(() => import('../pages/Content/FleetConfigurations/Hubs/Hubs'));
const FleetMakes = lazy(() => import('../pages/Content/FleetConfigurations/FleetMakes/FleetMakes'));
const Fleets = lazy(() => import('../pages/Content/FleetConfigurations/Fleets/Fleets'));
const FleetDetails = lazy(() => import('../pages/Content/FleetConfigurations/Fleets/FleetDetails/FleetDetails'));
const FleetDocuments = lazy(() => import('../pages/Content/FleetConfigurations/FleetDocument/FleetDocument'));
const Customers = lazy(() => import('../pages/Content/CustomerConfiguration/Customers/Customers'));
const CustomerContracts = lazy(() => import('../pages/Content/CustomerConfiguration/CustomerContracts/CustomerContracts'));
const Register = lazy(() => import('../pages/Auth/Register/Register'));
const RolesList = lazy(() => import('../pages/Settings/Roles/RolesList'));
const RoleForm = lazy(() => import('../pages/Settings/Roles/RoleForm'));
const PlatformRolesList = lazy(() => import('../pages/Settings/PlatformRoles/PlatformRolesList'));
const PlatformRoleForm = lazy(() => import('../pages/Settings/PlatformRoles/PlatformRoleForm'));
const OrganizationUsersList = lazy(() => import('../pages/Settings/Users/OrganizationUsersList'));
const OrganizationUserForm = lazy(() => import('../pages/Settings/Users/OrganizationUserForm'));
const PlatformUsersList = lazy(() => import('../pages/Settings/Users/PlatformUsersList'));
const PlatformUserForm = lazy(() => import('../pages/Settings/Users/PlatformUserForm'));
const CustomerDetails = lazy(() => import('../pages/Content/CustomerConfiguration/CustomerDetails/CustomerDetails'));

export const routes = {
  public: [
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/register',
      component: Register,
    }
  ],
  private: [
    {
      path: '/',
      name: 'Home',
      icon: HomeOutlined,
      component: Home,
      showInSidebar: true,
    },
    {
      name: 'Job Orders',
      icon: FileTextOutlined,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_JOB_ORDERS,
      children: [
        {
          path: '/single/trips',
          name: 'Single Trips',
          component: SinglesTrips,
          icon: TruckFilled,
          showInSidebar: true,
          permission: PermissionEnum.VIEW_JOB_ORDERS,
        },
        {
          path: '/multi/trips',
          name: 'Multi Trips',
          component: MultiTrips,
          icon: TruckFilled,
          showInSidebar: true,
          permission: PermissionEnum.VIEW_JOB_ORDERS,
        }
      ]
    },
    {
      name: 'Dispatch Management',
      icon: TruckFilled,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_DISPATCH_ORDERS,
      children: [
        {
          path: '/dispatch/orders',
          name: 'Dispatch Orders',
          component: DispatchOrders,
          icon: FileTextOutlined,
          showInSidebar: true,
          permission: PermissionEnum.VIEW_DISPATCH_ORDERS,
        },
      ]
    },
    {
      name: 'Employees',
      icon: UsergroupAddOutlined,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_EMPLOYEES,
      children: [
        {
          path: '/employees',
          name: 'Employees',
          component: Employees,
          icon: TeamOutlined,
          showInSidebar: true,
          permission: PermissionEnum.VIEW_EMPLOYEES,
        },
        {
            path: '/employees/:id',
            name: 'Employee Details',
            component: EmployeeDetails,
            permission: PermissionEnum.VIEW_EMPLOYEES,
            showInSidebar: false,
          },
        {
          path: '/departments',
          name: 'Departments',
          component: Departments,
          icon: BankOutlined,
          permission: PermissionEnum.VIEW_DEPARTMENTS,
          showInSidebar: true,
        },
        {
          path: '/designation',
          name: 'Designations',
          component: Designations,
          icon: UserOutlined,
          permission: PermissionEnum.VIEW_DESIGNATIONS,
          showInSidebar: true,
        },
        {
          path: '/document-type',
          name: 'Document Types',
          component: EmployeeDocumentTypes,
          icon: FileOutlined,
          permission: PermissionEnum.VIEW_DOCUMENT_TYPES,
          showInSidebar: true,
        }
      ]
    },
    {
      name: 'Fleets',
      icon: CarOutlined,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_FLEETS,
      children: [
        {
          path: '/status',
          name: 'Status',
          component: FleetStatuses,
          icon: TagsOutlined,
          permission: PermissionEnum.VIEW_STATUSES,
          showInSidebar: true,
        },
        {
          path: '/document-types',
          name: 'Document Types',
          component: FleetDocumentTypes,
          icon: FileOutlined,
          permission: PermissionEnum.VIEW_DOCUMENT_TYPES,
          showInSidebar: true,
        },
        {
          path: '/fleet-type',
          name: 'Fleet Types',
          component: FleetTypes,
          icon: CarOutlined,
          permission: PermissionEnum.VIEW_FLEET_TYPES,
          showInSidebar: true,
        },
        {
          path: '/trailer',
          name: 'Trailers',
          component: Trailers,
          icon: ContainerOutlined,
          permission: PermissionEnum.VIEW_TRAILERS,
          showInSidebar: true,
        },
        {
          path: '/hubs',
          name: 'Hubs',
          component: Hubs,
          icon: EnvironmentOutlined,
          permission: PermissionEnum.VIEW_HUBS,
          showInSidebar: true,
        },
        {
          path: '/fleet-makes',
          name: 'Fleet Makes',
          component: FleetMakes,
          icon: CarOutlined,
          permission: PermissionEnum.VIEW_FLEET_MAKES,
          showInSidebar: true,
        },
        {
          path: '/fleets',
          name: 'Fleets',
          component: Fleets,
          icon: CarOutlined,
          permission: PermissionEnum.VIEW_FLEETS,
          showInSidebar: true,
        },
        {
          path: '/fleets/:id',
          name: 'Fleet Details',
          component: FleetDetails,
          permission: PermissionEnum.VIEW_FLEETS,
          showInSidebar: false,
        },
        {
          path: '/fleet-document',
          name: 'Fleet Documents',
          component: FleetDocuments,
          icon: FileOutlined,
          permission: PermissionEnum.VIEW_FLEET_DOCUMENTS,
          showInSidebar: true,
        }
      ]
    },
    {
      name: 'Customers',
      icon: UserOutlined,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_CUSTOMERS,
      children: [
        {
          path: '/customers',
          name: 'Customers',
          component: Customers,
          icon: TeamOutlined,
          permission: PermissionEnum.VIEW_CUSTOMERS,
          showInSidebar: true,
        },
        {
          path: '/customers/:id',
          name: 'Customer Details',
          component: CustomerDetails,
          permission: PermissionEnum.VIEW_CUSTOMERS,
          showInSidebar: false,
        },
        {
          path: '/customer-contracts',
          name: 'Contracts',
          component: CustomerContracts,
          icon: FileTextOutlined,
          permission: PermissionEnum.VIEW_CUSTOMER_CONTRACTS,
          showInSidebar: true,
        }
      ]
    },
    {
      name: 'Configuration',
      icon: SettingOutlined,
      showInSidebar: true,
      children: [
        {
          path: '/services',
          name: 'Services',
          component: Services,
          icon: BuildOutlined,
          permission: PermissionEnum.VIEW_SERVICES,
          showInSidebar: true,
        },
        {
          path: '/categories',
          name: 'Categories',
          component: Categories,
          icon: ContainerOutlined,
          permission: PermissionEnum.VIEW_CATEGORIES,
          showInSidebar: true,
        },
        {
          path: '/currencies',
          name: 'Currencies',
          component: Currencies,
          icon: DollarOutlined,
          permission: PermissionEnum.VIEW_CURRENCIES,
          showInSidebar: true,
        },
        {
          path: '/load-types',
          name: 'Load Types',
          component: LoadTypes,
          icon: ContainerOutlined,
          permission: PermissionEnum.VIEW_LOAD_TYPES,
          showInSidebar: true,
        },
        {
          path: '/location',
          name: 'Locations',
          component: Locations,
          icon: EnvironmentOutlined,
          permission: PermissionEnum.VIEW_LOCATIONS,
          showInSidebar: true,
        },
        {
          path: '/tags',
          name: 'Tags',
          component: Tags,
          icon: TagsOutlined,
          permission: PermissionEnum.VIEW_TAGS,
          showInSidebar: true,
        }
      ]
    },
    {
      name: 'Organizations',
      icon: TeamOutlined,
      showInSidebar: true,
      permission: PermissionEnum.VIEW_ALL_ORGANIZATIONS,
      deploymentMode: 'multi-tenant',
      children: [
        {
          path: '/organizations',
          name: 'Organizations',
          component: Organizations,
          permission: PermissionEnum.VIEW_ALL_ORGANIZATIONS,
          showInSidebar: true,
        },
        {
          path: '/organizations/:id',
          name: 'organization_details',
          component: OrganizationDetails,
          permission: PermissionEnum.VIEW_ALL_ORGANIZATIONS,
          showInSidebar: false,
        }
      ]
    },
    {
      name: 'Settings',
      icon: SettingOutlined,
      showInSidebar: true,
      permission: PermissionEnum.MANAGE_ORGANIZATION,
      children: [
        {
          path: '/settings/roles',
          name: 'Roles & Permissions',
          component: RolesList,
          icon: TeamOutlined,
          permission: PermissionEnum.MANAGE_ORGANIZATION_ROLES,
          showInSidebar: true,
        },
        {
          path: '/settings/roles/create',
          name: 'Create Role',
          component: RoleForm,
          permission: PermissionEnum.MANAGE_ORGANIZATION_ROLES,
          showInSidebar: false,
        },
        {
          path: '/settings/roles/:id/edit',
          name: 'Edit Role',
          component: RoleForm,
          permission: PermissionEnum.MANAGE_ORGANIZATION_ROLES,
          showInSidebar: false,
        },
        {
          path: '/settings/users',
          name: 'Organization Users',
          component: OrganizationUsersList,
          icon: UserOutlined,
          showInSidebar: true,
          permission: PermissionEnum.MANAGE_ORGANIZATION_USERS
        },
        {
          path: '/settings/users/create',
          name: 'Create User',
          component: OrganizationUserForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_ORGANIZATION_USERS
        },
        {
          path: '/settings/users/:id/edit',
          name: 'Edit User',
          component: OrganizationUserForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_ORGANIZATION_USERS
        }
      ]
    },
    {
      name: 'Platform Settings',
      icon: SettingOutlined,
      showInSidebar: true,
      permission: PermissionEnum.ACCESS_PLATFORM_ADMIN,
      deploymentMode: 'multi-tenant',
      children: [
        {
          path: '/settings/platform-roles',
          name: 'Platform Roles',
          component: PlatformRolesList,
          icon: TeamOutlined,
          showInSidebar: true,
          permission: PermissionEnum.MANAGE_PLATFORM_ROLES,
        },
        {
          path: '/settings/platform-roles/create',
          name: 'Create Platform Role',
          component: PlatformRoleForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_PLATFORM_ROLES,
        },
        {
          path: '/settings/platform-roles/:id/edit',
          name: 'Edit Platform Role',
          component: PlatformRoleForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_PLATFORM_ROLES,
        },
        {
          path: '/settings/platform-users',
          name: 'Platform Users',
          component: PlatformUsersList,
          icon: UserOutlined,
          showInSidebar: true,
          permission: PermissionEnum.MANAGE_PLATFORM_USERS
        },
        {
          path: '/settings/platform-users/create',
          name: 'Create Platform User',
          component: PlatformUserForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_PLATFORM_USERS
        },
        {
          path: '/settings/platform-users/:id/edit',
          name: 'Edit Platform User',
          component: PlatformUserForm,
          showInSidebar: false,
          permission: PermissionEnum.MANAGE_PLATFORM_USERS
        }
      ]
    }
  ]
};
