import Environment from "./baseUrl";
import axios from "axios";

// const getHeaders = async (token) => {
//   if (token) {
//     return {
//       Authorization: "Bearer " + token,
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       "Access-Control-Allow-Origin": "*",
//     };
//   } else {
//     return {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       // 'Authorization':  APP_NORMAL,
//     };
//   }
// };

const getHeaders = async (token, isFileUpload = false) => {
    const headers = {
      Authorization: token ? "Bearer " + token : undefined,
      "Content-Type": isFileUpload ? undefined : "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    if (!token) {
      delete headers.Authorization;
    }
    if (isFileUpload) {
      delete headers["Content-Type"];
    }
    return headers;
  };

export const doPost = async (url, data, token, isFileUpload = false) => {
  try {
    // Create a new config object for each request
    const headers = await getHeaders(token, isFileUpload);
    const config = {
      method: "POST",
      url: Environment.API_BASE_URL + url,
      headers: headers,
      data: data
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const doPatch = async (url, data, token, isFileUpload = false) => {
  try {
    // Create a new config object for each request
    const headers = await getHeaders(token, isFileUpload);
    const config = {
      method: "POST",
      params: { _method: 'PATCH' },
      url: Environment.API_BASE_URL + url,
      headers: headers,
      data: data
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const doPut = async (url, data, token, isFileUpload = false) => {
  try {
    // Create a new config object for each request
    const headers = await getHeaders(token, isFileUpload);
    const config = {
      method: "POST",
      params: { _method: 'PUT' },
      url: Environment.API_BASE_URL + url,
      headers: headers,
      data: data
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const doGet = async (url, token, data) => {
  try {
    // Create a new config object for each request
    const headers = await getHeaders(token);
    const config = {
      method: "GET",
      url: Environment.API_BASE_URL + url,
      headers: headers,
      params: data || {}
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const doDelete = async (url, data, token) => {
  try {
    // Create a new config object for each request
    const headers = await getHeaders(token);
    const config = {
      method: "DELETE",
      params: { _method: 'DELETE' },
      url: Environment.API_BASE_URL + url,
      headers: headers,
      data: data
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw error;
  }
};
