import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';
import { useTranslation } from 'react-i18next';

const SidebarNavigation = () => {
  const routes = useRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  // Find parent key for a given path
  const findParentKey = (routes, targetPath, parentKey = null) => {
    for (const route of routes) {
      if (route.path === targetPath) {
        return parentKey;
      }
      if (route.children) {
        const found = findParentKey(route.children, targetPath, route.name);
        if (found) return found;
      }
    }
    return null;
  };

  // Find the matching route for the current path
  const findMatchingRoute = (routes, currentPath) => {
    for (const route of routes) {
      // Check if the current path matches the route path
      // Handle dynamic routes (e.g., /organizations/:id)
      if (route.path) {
        const routePathRegex = new RegExp(
          '^' + route.path.replace(/:[^\s/]+/g, '[^/]+') + '$'
        );
        if (routePathRegex.test(currentPath)) {
          return route;
        }
      }

      // Check children routes
      if (route.children) {
        const matchingChild = findMatchingRoute(route.children, currentPath);
        if (matchingChild) return matchingChild;
      }
    }
    return null;
  };

  // Update selected keys when route changes
  useEffect(() => {
    const currentPath = location.pathname;
    const matchingRoute = findMatchingRoute(routes, currentPath);

    if (matchingRoute) {
      setSelectedKeys([matchingRoute.path]);
      const parentKey = findParentKey(routes, matchingRoute.path);
      if (parentKey) {
        setOpenKeys(prevKeys => {
          const newKeys = [...new Set([...prevKeys, parentKey])];
          return newKeys;
        });
      }
    }
  }, [location.pathname, routes]);

  const createMenuItems = (routes) => {
    return routes
      .filter(route => route.showInSidebar)
      .map(route => {
        const menuItem = {
          key: route.path || route.name,
          icon: route.icon && <route.icon />,
          label: t(route.name),
          onClick: route.path ? () => navigate(route.path) : undefined,
        };

        if (route.children) {
          menuItem.children = createMenuItems(route.children);
        }

        return menuItem;
      });
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <Menu
      mode="inline"
      items={createMenuItems(routes)}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
    />
  );
};

export default SidebarNavigation;
