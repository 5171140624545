import NotificationWithIcon from '../components/NotificationWithIcon';

/**
 * Generic error handler for API calls
 * @param {Object} error - The error object from axios
 * @param {Object} options - Configuration options
 * @param {Object} options.form - Ant Design form instance for validation errors
 * @param {Function} options.onAuthError - Custom handler for auth errors
 * @param {Function} options.onPermissionError - Custom handler for permission errors
 * @param {Function} options.onNotFoundError - Custom handler for not found errors
 * @param {Function} options.onValidationError - Custom handler for validation errors
 * @param {Function} options.onServerError - Custom handler for server errors
 * @param {Function} options.onCustomError - Custom handler for specific API errors
 * @param {string} options.defaultMessage - Default error message if none is provided
 * @returns {string} The error message
 */
const handleApiError = (error, options = {}) => {
  console.log('error: ', error);
  console.log('options: ', options);

  const {
    form = null,
    onAuthError = null,
    onPermissionError = null,
    onNotFoundError = null,
    onValidationError = null,
    onServerError = null,
    onCustomError = null,
    defaultMessage = 'Something went wrong. Please try again.',
    showNotification = true
  } = options;

  // If it's not an axios error response
  if (!error.response) {
    const errorMessage = error.message || defaultMessage;
    if (showNotification) NotificationWithIcon('error', errorMessage);
    return errorMessage;
  }

  const { status, data } = error.response;

  // Authentication error (401)
  if (status === 401) {
    if (onAuthError) {
      onAuthError(error);
    } else {
      const errorMsg = data.message || 'Your session has expired. Please login again.';
      if (showNotification) NotificationWithIcon('error', errorMsg);
      return errorMsg;
    }
  }

  // Authorization error (403)
  else if (status === 403) {
    if (onPermissionError) {
      onPermissionError(error);
    } else {
      const errorMsg = data.message || "You don't have permission to perform this action.";
      if (showNotification) NotificationWithIcon('error', errorMsg);
      return errorMsg;
    }
  }

  // Not found (404)
  else if (status === 404) {
    if (onNotFoundError) {
      onNotFoundError(error);
    } else {
      const errorMsg = data.message || 'The requested resource was not found.';
      if (showNotification) NotificationWithIcon('error', errorMsg);
      return errorMsg;
    }
  }

  // Validation errors (422)
  else if (status === 422) {
    if (onValidationError) {
      onValidationError(error);
    } else if (form && data.errors) {
      // Set field errors in the form
      const fieldErrors = [];
      Object.keys(data.errors).forEach(field => {
        fieldErrors.push({
          name: field,
          errors: Array.isArray(data.errors[field]) ? data.errors[field] : [data.errors[field]]
        });
      });
      form.setFields(fieldErrors);

      // Create title and description for notification
      const mainMessage = data.message || 'Please fix the form errors';

      // Create a nicely formatted list of all errors for description
      let errorDescription = '';
      if (data.errors) {
        const errorList = [];
        Object.keys(data.errors).forEach(field => {
          const fieldErrors = Array.isArray(data.errors[field]) ? data.errors[field] : [data.errors[field]];
          fieldErrors.forEach(error => {
            errorList.push(`• ${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}: ${error}`);
          });
        });

        // Join all errors with line breaks
        errorDescription = errorList.join('\n');
      }

      if (showNotification) {
        // Use NotificationWithIcon for multiple errors
        NotificationWithIcon('error', mainMessage, errorDescription);
      }

      return mainMessage;
    } else {
      // If no form is provided but there are validation errors
      const mainMessage = data.message || 'Validation failed';

      // Create a nicely formatted list of all errors for description
      let errorDescription = '';
      if (data.errors) {
        const errorList = [];
        Object.keys(data.errors).forEach(field => {
          const fieldErrors = Array.isArray(data.errors[field]) ? data.errors[field] : [data.errors[field]];
          fieldErrors.forEach(error => {
            errorList.push(`• ${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}: ${error}`);
          });
        });

        // Join all errors with line breaks
        errorDescription = errorList.join('\n');
      }

      if (showNotification) {
        // Use NotificationWithIcon for multiple errors
        NotificationWithIcon('error', mainMessage, errorDescription);
      }

      return mainMessage;
    }
  }

  // Server errors (500)
  else if (status >= 500) {
    if (onServerError) {
      onServerError(error);
    } else {
      const errorMsg = data.message || 'Server error. Please try again later.';
      if (showNotification) NotificationWithIcon('error', errorMsg);
      return errorMsg;
    }
  }

  // Custom API errors (success: false)
  else if (data && data.success === false) {
    if (onCustomError) {
      onCustomError(error);
    } else {
      const errorMsg = data.message || defaultMessage;
      if (showNotification) NotificationWithIcon('error', errorMsg);
      return errorMsg;
    }
  }

  // Fallback for other error types
  else {
    const errorMsg =
      (data && data.message) ||
      (error.message) ||
      defaultMessage;

    if (showNotification) NotificationWithIcon('error', errorMsg);
    return errorMsg;
  }
};

export default handleApiError;
