import { useContext, useMemo } from 'react';
import { AppContext } from '../context/AppContextProvider';
import { routes } from '../config/routes';

export const useRoutes = () => {
  const { authUser, hasPermission, deploymentMode } = useContext(AppContext);

  const filteredRoutes = useMemo(() => {
    if (!authUser) {
      return routes.public;
    }

    const filterRoutesByPermission = (routes) => {
      return routes.filter(route => {
        // Check deployment mode
        if (route.deploymentMode && route.deploymentMode !== deploymentMode) {
          return false;
        }

        // Check permissions
        if (route.permission && !hasPermission(route.permission)) {
          return false;
        }

        // Filter children if they exist
        if (route.children) {
          route.children = filterRoutesByPermission(route.children);
          // Don't show parent if all children are filtered out
          if (route.children.length === 0) {
            return false;
          }
        }

        return true;
      });
    };

    return filterRoutesByPermission(routes.private);
  }, [authUser, hasPermission, deploymentMode]);

  return filteredRoutes;
};
