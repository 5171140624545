const RoleEnum = {
    PLATFORM_ADMIN: 'Platform Admin',
    ORGANIZATION_ADMIN: 'Organization Admin',
    DISPATCH_MANAGER: 'Dispatch Manager',
    SALES_OFFICER: 'Sales Officer',
    FLEET_MANAGER: 'Fleet Manager',
    DRIVER: 'Driver'
};

export default RoleEnum;
