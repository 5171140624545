import "../AuthStyle.css";
import "./register.css";
import { Form, Input, Button, Typography, Row, Col, Card, Steps, Space } from "antd";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { organizationService } from "../../../network/organization";
import { useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined, BankOutlined, MailOutlined, PhoneOutlined, GlobalOutlined, EnvironmentOutlined, SafetyOutlined } from "@ant-design/icons";
import handleApiError from '../../../network/errorHandler';
import NotificationWithIcon from '../../../components/NotificationWithIcon';

const { Title } = Typography;
const { Step } = Steps;

const Register = () => {
    const { deploymentMode } = useContext(AppContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    // Redirect if not multi-tenant
    if (deploymentMode !== 'multi-tenant') {
        return <Navigate to="/login" replace />;
    }

    const requestOTP = async (values) => {
        setLoading(true);
        try {
            const response = await organizationService.requestOTP({
                contact_number: values.contact_number
            });

            if (response.data?.message) {
                NotificationWithIcon('success', t('otp_sent_successfully'));
                setFormData(values);
                setOtpSent(true);
                setCurrentStep(1);
                setTimeLeft(600); // 10 minutes in seconds
                // Start countdown timer
                const timer = setInterval(() => {
                    setTimeLeft((prev) => {
                        if (prev <= 1) {
                            clearInterval(timer);
                            return 0;
                        }
                        return prev - 1;
                    });
                }, 1000);
            }
        } catch (error) {
            handleApiError(error, {
                form,
                showNotification: true,
                defaultMessage: t('otp_request_failed')
            });
        } finally {
            setLoading(false);
        }
    };

    const verifyAndRegister = async (values) => {
        setLoading(true);
        try {
            const response = await organizationService.verifyAndRegister({
                ...formData,
                otp: values.otp,
            });

            if (response.data) {
                NotificationWithIcon('success', t('registration_successful'));
                navigate('/login');
            }
        } catch (error) {
            handleApiError(error, {
                form,
                defaultMessage: t('registration_failed'),
                // Optional custom handlers
                onAuthError: (err) => {
                    // Custom handling for auth errors
                    navigate('/login');
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const renderRegistrationForm = () => (
        <Form
            form={form}
            onFinish={requestOTP}
            layout="vertical"
            className="register-form"
        >
            <Row gutter={24}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="organization_name"
                        label={t('organization_name')}
                        rules={[
                            { required: true, message: t('please_enter_organization_name') },
                            { max: 255, message: t('organization_name_too_long') }
                        ]}
                    >
                        <Input prefix={<BankOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="domain"
                        label={t('domain')}
                    >
                        <Input prefix={<GlobalOutlined />} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="name"
                        label={t('admin_name')}
                        rules={[
                            { required: true, message: t('please_enter_admin_name') },
                            { max: 255, message: t('name_too_long') }
                        ]}
                    >
                        <Input prefix={<UserOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="contact_number"
                        label={t('contact_number')}
                        rules={[
                            { required: true, message: t('please_enter_contact_number') },
                            { pattern: /^\+[1-9]\d{1,14}$/, message: t('invalid_phone_format') }
                        ]}
                    >
                        <Input prefix={<PhoneOutlined />} placeholder="+966501234567" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="email"
                        label={t('email')}
                        rules={[
                            { required: true, message: t('please_enter_email') },
                            { type: 'email', message: t('please_enter_valid_email') }
                        ]}
                    >
                        <Input prefix={<MailOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="password"
                        label={t('password')}
                        rules={[
                            { required: true, message: t('please_enter_password') },
                            { min: 8, message: t('password_too_short') }
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name="password_confirmation"
                        label={t('confirm_password')}
                        dependencies={['password']}
                        rules={[
                            { required: true, message: t('please_confirm_password') },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('passwords_do_not_match')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} lg={8}>
                    <Form.Item name="country" label={t('country')}>
                        <Input prefix={<EnvironmentOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item name="city" label={t('city')}>
                        <Input prefix={<EnvironmentOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item name="address" label={t('address')}>
                        <Input prefix={<EnvironmentOutlined />} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item className="form-actions">
                <Button type="primary" htmlType="submit" size="large" className="register-button" loading={loading}>
                    {t('request_otp')}
                </Button>
                <div className="login-link">
                    {t('already_have_an_account')}{' '}
                    <Button type="link" onClick={() => navigate('/login')}>
                        {t('login_here')}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );

    const renderOTPForm = () => (
        <Form
            form={form}
            onFinish={verifyAndRegister}
            layout="vertical"
            className="register-form"
        >
            <div className="otp-section">
                <Title level={3}>{t('verify_contact_number')}</Title>
                <p className="otp-description">
                    {t('otp_sent_to')} {formData?.contact_number}
                </p>

                <Form.Item
                    name="otp"
                    rules={[
                        { required: true, message: t('please_enter_otp') },
                        { pattern: /^\d{6}$/, message: t('invalid_otp_format') }
                    ]}
                >
                    <Input
                        prefix={<SafetyOutlined />}
                        placeholder="123456"
                        maxLength={6}
                        size="large"
                    />
                </Form.Item>

                {timeLeft > 0 && (
                    <div className="timer">
                        {t('otp_expires_in')} {formatTime(timeLeft)}
                    </div>
                )}

                <Space className="otp-actions">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {t('verify_and_register')}
                    </Button>
                    <Button
                        onClick={() => setCurrentStep(0)}
                        disabled={loading}
                    >
                        {t('edit_details')}
                    </Button>
                </Space>

                {timeLeft === 0 && (
                    <Button
                        type="link"
                        onClick={() => requestOTP(formData)}
                        disabled={loading}
                    >
                        {t('resend_otp')}
                    </Button>
                )}
            </div>
        </Form>
    );

    return (
        <div className="register-container">
            <Card className="register-card">
                <Steps current={currentStep} className="register-steps">
                    <Step title={t('organization_details')} />
                    <Step title={t('verify_phone')} />
                </Steps>

                <div className="register-content">
                    {currentStep === 0 ? renderRegistrationForm() : renderOTPForm()}
                </div>
            </Card>
        </div>
    );
};

export default Register;
