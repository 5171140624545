import React, { useContext, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../context/AppContextProvider';
import { Spin } from 'antd';

const RouteGuard = ({ component: Component, permission, deploymentMode }) => {
  const { authUser, hasPermission, deploymentMode: currentMode } = useContext(AppContext);

  if (!authUser) {
    return <Navigate to="/login" replace />;
  }

  if (deploymentMode && deploymentMode !== currentMode) {
    return <Navigate to="/" replace />;
  }

  if (permission && !hasPermission(permission)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Suspense fallback={<Spin size="large" />}>
      <Component />
    </Suspense>
  );
};

export default RouteGuard;
