import "./App.less";
import { useEffect, useContext } from "react";
import { ConfigProvider, Spin } from "antd";
import ar_EG from "antd/lib/locale/ar_EG";
import en_GB from "antd/lib/locale/en_GB";
import Login from "../Auth/Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import { AppContext } from "../../context/AppContextProvider";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { getMyProfile } from "../../network/network";
import { useTranslation } from "react-i18next";
import { OrganizationProvider } from "../../context/OrganizationContext";
import Register from "../Auth/Register/Register";

const App = () => {
  const {
    authUser,
    setAuthUser,
    fullPageLoading,
    setFullPageLoading,
    setActiveMenu,
    appConfig,
    deploymentMode
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isPublicRoute = location.pathname === '/login' ||
                         (deploymentMode === 'multi-tenant' && location.pathname === '/register');

    // If no token and not on a public route, redirect to login
    if (!token && !isPublicRoute) {
      navigate("/login", { state: { from: location.pathname }, replace: true });
      return;
    }

    // If we have a token but no authUser, fetch the profile
    if (token && !authUser) {
      setFullPageLoading(true);
      getMyProfile()
        .then((response) => {
          if (response.data.success) {
            setAuthUser(response.data.user);
            localStorage.setItem("profile", JSON.stringify(response.data.user));

            if (location.pathname === '/' || location.pathname === '/login') {
              const lastVisitedPage = JSON.parse(localStorage.getItem("last_visited_page"));
              if (lastVisitedPage?.path && lastVisitedPage.path !== '/login') {
                setActiveMenu(lastVisitedPage.key);
                navigate(lastVisitedPage.path, { replace: true });
              } else {
                navigate('/home', { replace: true });
              }
            } else {
              setActiveMenu(location.pathname.split('/')[1] || 'home');
            }
          } else {
            localStorage.clear();
            navigate("/login", { replace: true });
          }
        })
        .catch(() => {
          localStorage.clear();
          navigate("/login", { replace: true });
        })
        .finally(() => {
          setFullPageLoading(false);
        });
    }
  }, [authUser, location.pathname, navigate, setActiveMenu, setAuthUser, setFullPageLoading, deploymentMode]);

  useEffect(() => {
    if (appConfig.direction === "rtl" && i18n.language !== "ar-EG") {
      i18n.changeLanguage("ar-EG");
    } else if (appConfig.direction !== "rtl" && i18n.language !== "en-GB") {
      i18n.changeLanguage("en-GB");
    }
  }, [appConfig.direction, i18n]);

  return (
    <ConfigProvider
      direction={appConfig.direction}
      locale={appConfig.direction === "rtl" ? ar_EG : en_GB}
    >
      <OrganizationProvider>
        <div className={appConfig.direction === "rtl" ? "app_rtl" : "app_ltr"}>
          {fullPageLoading && (
            <div
              style={{
                textAlign: "center",
                paddingTop: "45vh",
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100vw",
                background: "#ffffff",
                zIndex: "999999",
              }}
            >
              <Spin size="large" />
            </div>
          )}
          {!fullPageLoading && (
            <Routes>
              <Route
                path="/login"
                element={!authUser ? <Login /> : <Dashboard />}
              />
              <Route
                path="/register"
                element={deploymentMode === 'multi-tenant' ? <Register /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/*"
                element={authUser ? <Dashboard /> : <Login />}
              />
            </Routes>
          )}
        </div>
      </OrganizationProvider>
    </ConfigProvider>
  );
};

export default App;
