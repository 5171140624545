import { doGet, doPost, doPut } from "./config";

export const organizationService = {
  getOrganizations: (params) =>
    doGet("/organizations", localStorage.getItem("token"), params),

  register: (data) =>
    doPost("/organizations/register", data, localStorage.getItem("token"), true),

  getCurrent: () =>
    doGet("/organizations/current", localStorage.getItem("token")),

  getById: (id) =>
    doGet(`/organizations/${id}`, localStorage.getItem("token")),

  update: (id, data) =>
    doPut(`/organizations/${id}`, data, localStorage.getItem("token"), true),

  updateSubscription: async (organizationId, subscriptionData) => {
    return await doPut(`/organizations/${organizationId}/subscription`, subscriptionData, localStorage.getItem("token"), true);
  },

  toggleActive: (id) =>
    doPut(`/organizations/${id}/toggle-active`, {}, localStorage.getItem("token")),

  getStatistics: (id) =>
    doGet(`/organizations/${id}/statistics`, localStorage.getItem("token")),

  requestOTP: (data) =>
    doPost("/organizations/request-otp", data, null, false),

  verifyAndRegister: (data) =>
    doPost("/organizations/verify-and-register", data, null, false)
};
