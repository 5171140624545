import "./Dashboard.css";
import React, { useEffect, useState, useContext } from "react";
import { Menu, Layout, Radio } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import AppRoutes from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateAuthDetails, userLogout, getDashboardData } from "../../network/network";
import SidebarNavigation from "../../components/SidebarNavigation";
import {
    COMPANY_STATUS_ACTIVE,
    COMPANY_STATUS_BLOCKED,
    COMPANY_STATUS_SUBSCRIPTION_ENDED,
    COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW,
    COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
} from "../../configs/constants";
import Home from "../Content/Home/Home";

const { Header, Sider, Content, Footer } = Layout;

const Dashboard = () => {
    const {
        authUser,
        activeMenu,
        setActiveMenu,
        appConfig,
        setAppConfigWrapper,
        organization,
        deploymentMode,
        setAuthUser,
    } = useContext(AppContext);
    const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
    const [showPageOnly, setShowPageOnly] = useState(false);
    const [blockRouting, setBlockRouting] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Call the dashboard API and log its response
        getDashboardData()
            .then(response => {
                console.log("Dashboard Data Response:", response);
            })
            .catch(error => {
                console.error("Error fetching dashboard data:", error);
            });
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            localStorage.clear();
            navigate("/login", { replace: true });
            return;
        }

        const currentPath = location.pathname;
        if (currentPath !== "/" && currentPath !== "/login") {
            const lastVisitedPage = {
                path: currentPath,
                key: currentPath.split("/")[1] || "home"
            };
            localStorage.setItem("last_visited_page", JSON.stringify(lastVisitedPage));
            setActiveMenu(lastVisitedPage.key);
        }
    }, [location.pathname, navigate, setActiveMenu]);

    useEffect(() => {
        if (deploymentMode === 'multi-tenant' && organization) {
            if (organization.status === COMPANY_STATUS_ACTIVE) {
                setBlockRouting(false);
                setShowPageOnly(false);
            } else if (organization.status === COMPANY_STATUS_SUBSCRIPTION_ENDED) {
                setBlockRouting(true);
                setShowPageOnly("SUBSCRIPTION");
            } else if (
                organization.status === COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW
            ) {
                setBlockRouting(true);
                setShowPageOnly("SUBSCRIPTION");
            } else if (organization.status === COMPANY_STATUS_BLOCKED) {
                setBlockRouting(true);
                setShowPageOnly("INVOICES");
            } else if (
                organization.status ===
                COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
            ) {
                setShowPageOnly("INVOICES");
            }
        }

        setAppConfigWrapper(authUser.app_config);
        // eslint-disable-next-line
    }, [organization?.status, deploymentMode]);

    useEffect(() => {
        if (
            authUser &&
            Object.keys(appConfig).length > 0 &&
            JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
        ) {
            const formData = new FormData();
            formData.append("app_config", JSON.stringify(appConfig));
            updateAuthDetails(formData).then((res) => {
                console.log("App Config Updated");
            });
        }
    }, [appConfig, authUser]);

    const saveCurrentPageView = (key) => {
        setActiveMenu(key);
        localStorage.setItem(
            "last_visited_page",
            JSON.stringify({
                key: key,
                path: location.pathname,
            })
        );
    };

    const logoutUser = () => {
        userLogout().then(() => {
            setAuthUser(null);
            localStorage.clear();
            navigate("/login", { replace: true });
        }).catch(() => {
            setAuthUser(null);
            localStorage.clear();
            navigate("/login", { replace: true });
        });
    };

    return (
        <Layout id="app" hasSider={true}>
            <Sider
                collapsible
                theme="light"
                trigger={null}
                defaultCollapsed={false}
                collapsed={isSiderCollapsed}
                collapsedWidth={window.innerWidth > 767 ? 80 : 0}
                width={250}
                breakpoint={"md"}
                style={{
                    background: 'linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '2px 0 20px 0 rgba(0, 0, 0, 0.05)',
                    minHeight: '100vh',
                    position: 'relative',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    zIndex: 1000,
                }}
            >
                <div
                    style={{
                        padding: "24px 16px",
                        marginBottom: "20px",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: 'white',
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="Truckala Logo"
                        width={isSiderCollapsed ? "40px" : "40px"}
                        height="40px"
                        style={{
                            objectFit: 'contain',
                            transition: 'all 0.3s ease',
                        }}
                    />
                    {!isSiderCollapsed && (
                        <h2 style={{
                            marginLeft: "12px",
                            fontFamily: "'Ubuntu', 'Roboto', sans-serif",
                            fontWeight: "600",
                            fontSize: "20px",
                            background: 'linear-gradient(45deg, #1890ff, #096dd9)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            marginBottom: 0,
                            transition: 'all 0.3s ease',
                            opacity: isSiderCollapsed ? 0 : 1,
                        }}>Truckala</h2>
                    )}
                </div>
                <SidebarNavigation />
            </Sider>

            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: "0 20px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#ffffff",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1000,
                    }}
                >
                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{
                            borderBottom: "none",
                            minWidth: "300px",
                        }}
                        items={[
                            {
                                key: "sidebar-collapse-menu-item",
                                label: isSiderCollapsed ? (
                                    <MenuUnfoldOutlined
                                        onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                                        style={{ fontSize: "18px", cursor: "pointer", transition: "color 0.3s" }}
                                    />
                                ) : (
                                    <MenuFoldOutlined
                                        onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                                        style={{ fontSize: "18px", cursor: "pointer", transition: "color 0.3s" }}
                                    />
                                ),
                            },
                            {
                                key: "top-navbar-0",
                                style: {
                                    borderBottom: "none",
                                },
                                label: (
                                    <Radio.Group
                                        value={appConfig.direction}
                                        onChange={(e) => {
                                            setAppConfigWrapper({ direction: e.target.value });
                                        }}
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value="ltr">English</Radio.Button>
                                        <Radio.Button value="rtl">عربي</Radio.Button>
                                    </Radio.Group>
                                ),
                            },
                        ]}
                    />
                    {authUser.business_name && <h3 style={{ margin: 0, fontWeight: "bold", color: "#1890ff" }}>{authUser.business_name}</h3>}
                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{
                            borderBottom: "none",
                            display: "block",
                            minWidth: "300px",
                            textAlign: appConfig.direction === "rtl" ? "left" : "right",
                        }}
                        items={[
                            {
                                key: "top-navbar-3",
                                label: (
                                    <span
                                        className="submenu-title-wrapper"
                                        style={{ width: "150px", cursor: "pointer", display: "flex", alignItems: "center" }}
                                    >
                                        {authUser.name + " "}
                                        <DownOutlined style={{ marginLeft: "5px" }} />
                                    </span>
                                ),
                                children: [
                                    {
                                        key: "31",
                                        label: t("logout"),
                                        onClick: logoutUser,
                                    },
                                ],
                            },
                        ]}
                    />
                </Header>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        padding: "0",
                        background: "transparent",
                        minHeight: "80vh",
                    }}
                >
                    {blockRouting ? (
                        <>{showPageOnly === "HOME" && <Home />}</>
                    ) : (
                        <AppRoutes />
                    )}
                </Content>

                <Footer
                    className="dashboard-footer"
                    style={{
                        textAlign: "center",
                        backgroundColor: "#ffffff",
                        padding: "16px 50px",
                        borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                        boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.03)",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <span style={{ fontWeight: "500", color: "#595959" }}>
                                Truckala © {new Date().getFullYear()}
                            </span>
                            <span style={{ margin: "0 8px", color: "#d9d9d9" }}>|</span>
                            <span style={{ color: "#8c8c8c" }}>All rights reserved</span>
                        </div>
                    </div>
                </Footer>
            </Layout>
        </Layout>
    );
};

export default Dashboard;
