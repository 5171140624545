import { doDelete, doGet, doPost, doPut } from "./config";

// -------------------- Dashboard API's-----------------------------------

export const getDashboardData = () => {
  return doGet("/dashboard", localStorage.getItem("token"));
};

// =====================Dashboard API's end===============================

// -------------------- Auth API's-----------------------------------

export const userLogin = (data) => {
  return doPost("/login", data, "");
};

export const userLogout = (data) => {
  return doPost("/logout", data, localStorage.getItem("token"));
};

export const getMyProfile = () => {
  return doGet("/is-login", localStorage.getItem("token"));
};

export const updateAuthDetails = (data) => {
  return doPost(`/users/update`, data, localStorage.getItem("token"));
};

// =====================Auth API's end===============================

// ------------------- Services API's ----------------------------

export const addService = (data) => {
  return doPost("/add-service", data, localStorage.getItem("token"));
};

export const updateService = (data) => {
  return doPut(
    `/update-service/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const getService = () => {
  return doGet("/get-services", localStorage.getItem("token"));
};

export const deleteService = (id) => {
  return doDelete(`/delete-service/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Services API's End  ==================================

//  --------------------  Categories API's --------------------------

export const addCategory = (data) => {
  return doPost("/add-category", data, localStorage.getItem("token"));
};

export const getCategory = () => {
  return doGet("/get-categories", localStorage.getItem("token"));
};

export const updateCategory = (data) => {
  return doPut(
    `/update-category/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteCategory = (id) => {
  return doDelete(`/delete-category/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Categories API's End  ==================================

//  --------------------  Currencies API's --------------------------

export const addCurrency = (data) => {
  return doPost("/add-currency", data, localStorage.getItem("token"));
};

export const getCurrency = () => {
  return doGet("/get-currencies", localStorage.getItem("token"));
};

export const updateCurrency = (data) => {
  return doPut(
    `/update-currency/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteCurrency = (id) => {
  return doDelete(`/delete-currency/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Currencies API's End  ==================================

//  --------------------  Load Types API's --------------------------

export const addLoadType = (data) => {
  return doPost("/add-loadtype", data, localStorage.getItem("token"));
};

export const getLoadTypes = () => {
  return doGet("/get-loadtypes", localStorage.getItem("token"));
};

export const updateLoadType = (data) => {
  return doPut(
    `/update-loadtype/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteLoadType = (id) => {
  return doDelete(`/delete-loadtype/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Load Types API's End  ==================================

//  --------------------  Location Types API's --------------------------

export const addLocation = (data) => {
  return doPost("/add-location", data, localStorage.getItem("token"));
};

export const getLocation = () => {
  return doGet("/get-locations", localStorage.getItem("token"));
};

export const updateLocation = (data) => {
  return doPut(
    `/update-locations/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteLocation = (id) => {
  return doDelete(`/delete-location/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Location API's End  ==================================

//  -------------------- Tags  Types API's --------------------------

export const addTag = (data) => {
    return doPost("/tags", data, localStorage.getItem("token"));
  };

  export const getTags = (data) => {
    return doGet("/tags", localStorage.getItem("token"), data);
  };

  export const updateTag = (data) => {
    return doPut(`/tags/${data.id}`, data, localStorage.getItem("token"));
  };

  export const deleteTag = (id) => {
    return doDelete(`/tags/${id}`, {}, localStorage.getItem("token"));
  };

  // ==================== Tags API's End  ==================================

//  --------------------  Departments Types API's --------------------------

export const addDepartment = (data) => {
  return doPost("/add-department", data, localStorage.getItem("token"));
};

export const getDepartment = () => {
  return doGet("/get-departments", localStorage.getItem("token"));
};

export const updateDepartment = (data) => {
  return doPut(
    `/update-department/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDepartment = (id) => {
  return doDelete(
    `/delete-department/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Departments API's End  ==================================

//  -------------------- Designations  Types API's --------------------------

export const addDesignation = (data) => {
  return doPost("/add-designation", data, localStorage.getItem("token"));
};

export const getDesignation = () => {
  return doGet("/get-designations", localStorage.getItem("token"));
};

export const updateDesignation = (data) => {
  return doPut(
    `/update-designation/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDesignation = (id) => {
  return doDelete(
    `/delete-designation/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Designations API's End  ==================================


//  --------------------  vehicle Document Types  Types API's --------------------------

export const addDocumentType = (data) => {
  return doPost(
    "/add-documentType/vehicle",
    data,
    localStorage.getItem("token")
  );
};

export const getDocumentTypes = () => {
  return doGet("/get-documentTypes/vehicle", localStorage.getItem("token"));
};

export const updateDocumentType = (data) => {
  return doPut(
    `/update-documentType/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDocumentType = (id) => {
  return doDelete(
    `/delete-documentType/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== vehicle Document Types API's End  ==================================

//  -------------------- Employees API's --------------------------

export const addEmployee = (data) => {
  return doPost("/employees", data, localStorage.getItem("token"), true);
};

export const getEmployees = () => {
  return doGet("/employees", localStorage.getItem("token"));
};

export const showEmployee = (id) => {
  return doGet(`/employees/${id}`, localStorage.getItem("token"));
};

export const updateEmployee = (data, id) => {
  return doPut(
    `/employees/${id}`,
    data,
    localStorage.getItem("token"),
    true
  );
};

export const deleteEmployee = (id) => {
  return doDelete(`/employees/${id}`, {}, localStorage.getItem("token"));
};

export const rawDataEmployee = () => {
  return doGet("/raw-data-supervisor/employee", localStorage.getItem("token"));
};

export const linkEmployeeToUser = (employeeId, userId) => {
  return doPost(
    `/employees/${employeeId}/link-user`,
    { user_id: userId },
    localStorage.getItem("token")
  );
};

export const unlinkEmployeeFromUser = (employeeId) => {
  return doPost(
    `/employees/${employeeId}/unlink-user`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Employees API's End  ==================================

//  -------------------- Statuses  Types API's --------------------------

export const addStatus = (data) => {
  return doPost("/add-status", data, localStorage.getItem("token"));
};

export const getStatuses = () => {
  return doGet("/get-statuses", localStorage.getItem("token"));
};

export const updateStatus = (data) => {
  return doPut(
    `/update-status/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteStatus = (id) => {
  return doDelete(`/delete-status/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Statuses API's End  ==================================

//  -------------------- Fleet Document Types API's --------------------------

export const addDocumentsType = (data) => {
  return doPost("/add-documentType/fleet", data, localStorage.getItem("token"));
};

export const getDocumentsTypes = () => {
  return doGet("/get-documentTypes/fleet", localStorage.getItem("token"));
};

export const updateDocumentsType = (data) => {
  return doPut(
    `/update-documentType/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDocumentsType = (id) => {
  return doDelete(
    `/delete-documentType/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Fleet Document Types API's End  ==================================

//  -------------------- Fleet Types API's --------------------------

export const addFleetType = (data) => {
  return doPost("/add-fleet-type", data, localStorage.getItem("token"), true);
};

export const getFleetTypes = () => {
  return doGet("/get-fleet-types", localStorage.getItem("token"));
};

export const updateFleetType = (data, id) => {
  return doPut(
    `/update-fleet-type/${id}`,
    data,
    localStorage.getItem("token"),
    true
  );
};

export const deleteFleetType = (id) => {
  return doDelete(
    `/delete-fleet-type/${id}`,
    {},
    localStorage.getItem("token")
  );
};

export const rawDataFleetType = () => {
  return doGet(
    "/raw-data-supervisor/fleet_type",
    localStorage.getItem("token")
  );
};

// ==================== Fleet Types API's End  ==================================

//  -------------------- Fleet Makes  Types API's --------------------------

export const addFleetMake = (data) => {
    return doPost("/fleet-makes", data, localStorage.getItem("token"), true);
};

export const getFleetMakes = () => {
return doGet("/fleet-makes", localStorage.getItem("token"));
};

export const updateFleetMake = (data, id) => {
return doPut(`/fleet-makes/${id}`, data, localStorage.getItem("token"), true);
};

export const deleteFleetMake = (id) => {
return doDelete(`/fleet-makes/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Fleet Makes API's End  ==================================

//  -------------------- Hubs API's --------------------------

export const addHub = (data) => {
  return doPost("/add-hub", data, localStorage.getItem("token"));
};

export const getHubs = () => {
  return doGet("/get-hubs", localStorage.getItem("token"));
};

export const updateHub = (data) => {
  return doPut(`/update-hub/${data.id}`, data, localStorage.getItem("token"));
};

export const deleteHub = (id) => {
  return doDelete(`/delete-hub/${id}`, {}, localStorage.getItem("token"));
};

export const rawDataHub = () => {
  return doGet("/raw-data-supervisor/hub", localStorage.getItem("token"));
};

// ==================== Hubs API's End  ==================================

//  -------------------- Fleets API's --------------------------

export const addFleet = (data) => {
  return doPost("/add-fleet", data, localStorage.getItem("token"), true);
};

export const deleteFleet = (id) => {
  return doDelete(`/delete-fleet/${id}`, {}, localStorage.getItem("token"));
};

export const getFleets = (data) => {
  return doGet("/get-fleets", localStorage.getItem("token"), data);
};

export const showFleet = (id) => {
  return doGet(`/get-fleet/${id}`, localStorage.getItem("token"));
};

export const updateFleet = (data, id) => {
  return doPost(
    `/update-fleet/${id}`,
    data,
    localStorage.getItem("token"),
    true
  );
};

export const rawDataFleet = () => {
  return doGet("/raw-data-supervisor/fleet", localStorage.getItem("token"));
};

// ==================== Fleets API's End  ==================================

//  -------------------- FleetDocument API's --------------------------

export const addFleetDocument = (data) => {
  return doPost("/add-fleet-document", data, localStorage.getItem("token"), true);
};

export const deleteFleetDocument = (id) => {
  return doDelete(
    `/delete-fleet-document/${id}`,
    {},
    localStorage.getItem("token")
  );
};

export const getFleetDocuments = () => {
  return doGet("/get-fleet-documents", localStorage.getItem("token"));
};

export const updateFleetDocument = (data, fleetDocumentId) => {
  return doPost(
    `/update-fleet-document/${fleetDocumentId}`,
    data,
    localStorage.getItem("token"),
    true
  );
};

export const rawDataFleetDocuments = () => {
    return doGet("/raw-data-supervisor/fleet-documents", localStorage.getItem("token"));
  };

// ==================== FleetDocument API's End  ==================================

//  -------------------- Customers API's --------------------------

export const addCustomer = (data) => {
  return doPost("/customers", data, localStorage.getItem("token"), true);
};

export const getCustomers = () => {
  return doGet("/customers", localStorage.getItem("token"));
};

export const showCustomer = (id) => {
    return doGet(`/customers/${id}`, localStorage.getItem("token"));
  };

export const updateCustomer = (data, id) => {
  return doPut(
    `/customers/${id}`,
    data,
    localStorage.getItem("token"),
    true
  );
};

export const deleteCustomer = (id) => {
  return doDelete(`/customers/${id}`, {}, localStorage.getItem("token"));
};

export const rawDataCustomer = () => {
    return doGet("/raw-data-supervisor/customer", localStorage.getItem("token"));
  };

export const rawDataCustomerContract = () => {
  return doGet("/raw-data-supervisor/customer_contract", localStorage.getItem("token"));
};

// ==================== Customers API's End  ==================================

//  -------------------- Contacts API's --------------------------

export const addContact = (data, entityType, entityId) => {
    return doPost(`/contacts/${entityType}/${entityId}`, data, localStorage.getItem("token"), true);
  };

  export const addMultipleContacts = (data, entityType, entityId) => {
    return doPost(`/contacts/multiple/${entityType}/${entityId}`, data, localStorage.getItem("token"), true);
  };

  export const getContacts = (data, entityType, entityId) => {
    return doGet(`/contacts/${entityType}/${entityId}`, localStorage.getItem("token"), data);
  };

  export const updateContact = (data, id) => {
    return doPut(
      `/contacts/${id}`,
      data,
      localStorage.getItem("token")
    );
  };

  export const deleteContact = (id) => {
    return doDelete(
      `/contacts/${id}`,
      {},
      localStorage.getItem("token")
    );
  };

// ==================== Contacts API's End  ==================================

//  -------------------- Logs API's --------------------------

export const logs = (resource, resourceId) => {
  return doGet(`/logs/resource/${resource}/${resourceId}`, localStorage.getItem("token"));
};

// ==================== Logs API's End  ==================================

//  -------------------- Activities API's --------------------------

export const activities = (resource, resourceId, page) => {
  return doGet(`/activities?subject_type=${resource}&subject_id=${resourceId}&page=${page}`, localStorage.getItem("token"));
};

export const storeActivity = (data) => {
  return doPost(`/activities`, data, localStorage.getItem("token"));
};

export const updateActivity = (data) => {
  return doPut(`/activities/${data.id}`, data, localStorage.getItem("token"));
};

export const deleteActivity = (id) => {
  return doDelete(`/activities/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Activities API's End  ==================================

//  -------------------- Notes API's --------------------------

export const notes = (resource, resourceId, page) => {
  return doGet(`/notes?subject_type=${resource}&subject_id=${resourceId}&page=${page}`, localStorage.getItem("token"));
};

export const storeNote = (data) => {
  return doPost(`/notes`, data, localStorage.getItem("token"));
};

export const updateNote = (data) => {
  return doPut(`/notes/${data.id}`, data, localStorage.getItem("token"));
};

export const deleteNote = (id) => {
  return doDelete(`/notes/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Notes API's End  ==================================

//  -------------------- Customer Contract API's --------------------------

export const addCustomerContract = (data) => {
  return doPost("/add-customer-contract", data, localStorage.getItem("token"));
};

export const getCustomerContracts = (data) => {
  return doGet("/get-customer-contracts", localStorage.getItem("token"), data);
};

export const getCustomerContractsByCustomerId = (id) => {
  return doGet(`/customers/${id}/contracts`, localStorage.getItem("token"), {});
};

export const updateCustomerContract = (data) => {
  return doPut(
    `/update-customer-contract/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteCustomerContract = (id) => {
  return doDelete(
    `/delete-customer-contract/${id}`,
    {},
    localStorage.getItem("token")
  );
};

export const duplicateCustomerContract = (id) => {
  return doPost(
    `/duplicate-customer-contract/${id}`,
    {},
    localStorage.getItem("token")
  );
};

export const renewCustomerContract = () => {
  return doGet("/customer-contract-renew", localStorage.getItem("token"));
};

export const getTransportationsByContractId = (contractId) => {
  return doGet(`/customer-contracts/${contractId}/transportations`, localStorage.getItem("token"));
};

// ==================== Customer Contract API's End  ==================================

//  -------------------- Documents API's --------------------------

export const addDocument = (data, entityType, entityId) => {
  return doPost(`/documents/${entityType}/${entityId}`, data, localStorage.getItem("token"), true);
};

export const addMultipleDocuments = (data, entityType, entityId) => {
  return doPost(`/documents/multiple/${entityType}/${entityId}`, data, localStorage.getItem("token"), true);
};

export const getDocuments = (data, entityType, entityId) => {
  return doGet(`/documents/${entityType}/${entityId}`, localStorage.getItem("token"), data);
};

export const updateDocument = (data, id) => {
  return doPut(
    `/documents/${id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDocument = (id) => {
  return doDelete(
    `/documents/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Documents API's End  ==================================

//  -------------------- Customer Contract API's --------------------------

export const addSingleTripJobOrder = (data) => {
    return doPost("/job/orders/trip/single", data, localStorage.getItem("token"), true);
  };

  export const getSingleTripJobOrders = (data) => {
    return doGet("/job/orders/trip/single", localStorage.getItem("token"), data);
  };

  export const getSingleTripJobOrder = (id) => {
    return doGet(`/job/orders/trip/single/${id}`, localStorage.getItem("token"), null);
  };

  export const updateSingleTripJobOrder = (data, id) => {
    return doPut(
      `/job/orders/trip/single/${id}`,
      data,
      localStorage.getItem("token")
    );
  };

  export const deleteSingleTripJobOrder = (id) => {
    return doDelete(
      `/job/orders/trip/single/${id}`,
      {},
      localStorage.getItem("token")
    );
  };

  export const changeJobOrderStatus = (id, data) => {
    return doPut(
      `/job/orders/status/${id}`,
      data,
      localStorage.getItem("token")
    );
  };

  export const rawDataJobOrder = () => {
    return doGet("/raw-data-supervisor/job_order", localStorage.getItem("token"));
  };

  export const addMultiTripJobOrder = (data) => {
    return doPost("/job/orders/trip/multi", data, localStorage.getItem("token"), true);
  };

  export const getMultiTripJobOrders = (data) => {
    return doGet("/job/orders/trip/multi", localStorage.getItem("token"), data);
  };

  export const getMultiTripJobOrder = (id) => {
    return doGet(`/job/orders/trip/multi/${id}`, localStorage.getItem("token"), null);
  };

  export const updateMultiTripJobOrder = (data, id) => {
    return doPut(
      `/job/orders/trip/multi/${id}`,
      data,
      localStorage.getItem("token")
    );
  };

  export const deleteMultiTripJobOrder = (id) => {
    return doDelete(
      `/job/orders/trip/multi/${id}`,
      {},
      localStorage.getItem("token")
    );
  };

  export const changeMultiTripJobOrderStatus = (id, data) => {
    return doPut(
      `/job/orders/multitrip/status/${id}`,
      data,
      localStorage.getItem("token")
    );
  };


export const rawDataMultiJobOrder = () => {
    return doGet("/raw-data-supervisor/multi_job_order", localStorage.getItem("token"));
  };


  export const getDispatchOrders = (data) => {
    return doGet("/job/orders/dispatch", localStorage.getItem("token"), data);
  };

  export const getDispatchOrderById = (id) => {
    return doGet(`/job/orders/${id}/dispatch`, localStorage.getItem("token"), null);
};

export const rawDataDispatchOrder = () => {
    return doGet("/raw-data-supervisor/dispatch_orders", localStorage.getItem("token"));
  };
  // ==================== Customer Contract API's End  ==================================

//  -------------------- Trailers API's --------------------------

export const addTrailer = (data) => {
  return doPost("/trailers", data, localStorage.getItem("token"), true);
};

export const getTrailers = ({ page = 1, per_page = 10 } = {}) => {
    return doGet(
      `/trailers?page=${page}&per_page=${per_page}`,
      localStorage.getItem("token")
    );
  };

export const showTrailer = (id) => {
  return doGet(`/trailers/${id}`, localStorage.getItem("token"));
};

export const updateTrailer = (data, id) => {
  return doPut(`/trailers/${id}`, data, localStorage.getItem("token"), true);
};

export const deleteTrailer = (id) => {
  return doDelete(`/trailers/${id}`, {}, localStorage.getItem("token"));
};

// ==================== Trailers API's End  ==================================

//  --------------------  Document Types API's --------------------------

export const addDocumentTypeGeneral = (moduleType, data) => {
  return doPost(
    `/add-documentType/${moduleType}`,
    data,
    localStorage.getItem("token")
  );
};

export const getDocumentTypesGeneral = (moduleType) => {
  return doGet(`/get-documentTypes/${moduleType}`, localStorage.getItem("token"));
};

export const getDocumentTypeById = (id) => {
  return doGet(`/get-documentType/${id}`, localStorage.getItem("token"));
};

export const updateDocumentTypeGeneral = (data) => {
  return doPut(
    `/update-documentType/${data.id}`,
    data,
    localStorage.getItem("token")
  );
};

export const deleteDocumentTypeGeneral = (id) => {
  return doDelete(
    `/delete-documentType/${id}`,
    {},
    localStorage.getItem("token")
  );
};

// ==================== Document Types API's End  ==================================
