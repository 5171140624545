import React, { useEffect, createContext, useState } from "react";
import RoleEnum from "../constants/RoleEnum";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [authUser, setAuthUser] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");
  const [fullPageLoading, setFullPageLoading] = useState(true);
  const [appConfig, setAppConfig] = useState({});
  const [organization, setOrganization] = useState(null);
  const [deploymentMode, setDeploymentMode] = useState(
    process.env.REACT_APP_DEPLOYMENT_MODE || 'multi-tenant'
  );

  useEffect(() => {
    setTimeout(() => {
      setFullPageLoading(false);
    }, 1500);
  }, []);

  const setAppConfigWrapper = (obj = {}) => {
    if (JSON.stringify(appConfig) !== JSON.stringify(obj)) {
      setAppConfig({ ...appConfig, ...obj });
    }
  };

  const hasPermission = (permission) => {
    return authUser?.permissions?.includes(permission);
  };

  const hasRole = (role) => {
    return authUser?.roles?.includes(role);
  };

  const isPlatformAdmin = () => {
    return hasRole(RoleEnum.PLATFORM_ADMIN);
  };

  return (
    <AppContext.Provider
      value={{
        authUser,
        setAuthUser,
        activeMenu,
        setActiveMenu,
        fullPageLoading,
        setFullPageLoading,
        appConfig,
        setAppConfigWrapper,
        organization,
        setOrganization,
        deploymentMode,
        setDeploymentMode,
        hasPermission,
        hasRole,
        isPlatformAdmin
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
