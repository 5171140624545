const PermissionEnum = {
    ACCESS_PLATFORM_ADMIN: 'access_platform_admin',
    MANAGE_ORGANIZATIONS: 'manage_organizations',
    VIEW_ALL_ORGANIZATIONS: 'view_all_organizations',
    MANAGE_PLATFORM_SETTINGS: 'manage_platform_settings',
    VIEW_PLATFORM_STATISTICS: 'view_platform_statistics',
    MANAGE_SUBSCRIPTIONS: 'manage_subscriptions',
    MANAGE_PLATFORM_ROLES: 'manage_platform_roles',
    MANAGE_PLATFORM_USERS: 'manage_platform_users',
    MANAGE_ORGANIZATION: 'manage_organization',
    MANAGE_ORGANIZATION_ROLES: 'manage_organization_roles',
    MANAGE_SUBSCRIPTION: 'manage_subscription',
    MANAGE_ORGANIZATION_USERS: 'manage_organization_users',
    VIEW_DEPARTMENTS: 'view_departments',
    CREATE_DEPARTMENT: 'create_department',
    EDIT_DEPARTMENT: 'edit_department',
    DELETE_DEPARTMENT: 'delete_department',
    VIEW_DESIGNATIONS: 'view_designations',
    CREATE_DESIGNATION: 'create_designation',
    EDIT_DESIGNATION: 'edit_designation',
    DELETE_DESIGNATION: 'delete_designation',
    VIEW_DOCUMENT_TYPES: 'view_document_types',
    CREATE_DOCUMENT_TYPE: 'create_document_type',
    EDIT_DOCUMENT_TYPE: 'edit_document_type',
    DELETE_DOCUMENT_TYPE: 'delete_document_type',
    VIEW_STATUSES: 'view_statuses',
    CREATE_STATUS: 'create_status',
    EDIT_STATUS: 'edit_status',
    DELETE_STATUS: 'delete_status',
    VIEW_FLEET_TYPES: 'view_fleet_types',
    CREATE_FLEET_TYPE: 'create_fleet_type',
    EDIT_FLEET_TYPE: 'edit_fleet_type',
    DELETE_FLEET_TYPE: 'delete_fleet_type',
    VIEW_TRAILERS: 'view_trailers',
    CREATE_TRAILER: 'create_trailer',
    EDIT_TRAILER: 'edit_trailer',
    DELETE_TRAILER: 'delete_trailer',
    VIEW_HUBS: 'view_hubs',
    CREATE_HUB: 'create_hub',
    EDIT_HUB: 'edit_hub',
    DELETE_HUB: 'delete_hub',
    VIEW_FLEET_MAKES: 'view_fleet_makes',
    CREATE_FLEET_MAKE: 'create_fleet_make',
    EDIT_FLEET_MAKE: 'edit_fleet_make',
    DELETE_FLEET_MAKE: 'delete_fleet_make',
    VIEW_FLEET_DOCUMENTS: 'view_fleet_documents',
    CREATE_FLEET_DOCUMENT: 'create_fleet_document',
    EDIT_FLEET_DOCUMENT: 'edit_fleet_document',
    DELETE_FLEET_DOCUMENT: 'delete_fleet_document',
    VIEW_DOCUMENTS: 'view_documents',
    CREATE_DOCUMENT: 'create_document',
    EDIT_DOCUMENT: 'edit_document',
    DELETE_DOCUMENT: 'delete_document',
    VIEW_CUSTOMER_CONTRACTS: 'view_customer_contracts',
    CREATE_CUSTOMER_CONTRACT: 'create_customer_contract',
    EDIT_CUSTOMER_CONTRACT: 'edit_customer_contract',
    DELETE_CUSTOMER_CONTRACT: 'delete_customer_contract',
    VIEW_SERVICES: 'view_services',
    CREATE_SERVICE: 'create_service',
    EDIT_SERVICE: 'edit_service',
    DELETE_SERVICE: 'delete_service',
    VIEW_CATEGORIES: 'view_categories',
    CREATE_CATEGORY: 'create_category',
    EDIT_CATEGORY: 'edit_category',
    DELETE_CATEGORY: 'delete_category',
    VIEW_CURRENCIES: 'view_currencies',
    CREATE_CURRENCY: 'create_currency',
    EDIT_CURRENCY: 'edit_currency',
    DELETE_CURRENCY: 'delete_currency',
    VIEW_LOAD_TYPES: 'view_load_types',
    CREATE_LOAD_TYPE: 'create_load_type',
    EDIT_LOAD_TYPE: 'edit_load_type',
    DELETE_LOAD_TYPE: 'delete_load_type',
    VIEW_LOCATIONS: 'view_locations',
    CREATE_LOCATION: 'create_location',
    EDIT_LOCATION: 'edit_location',
    DELETE_LOCATION: 'delete_location',
    VIEW_TAGS: 'view_tags',
    CREATE_TAG: 'create_tag',
    EDIT_TAG: 'edit_tag',
    DELETE_TAG: 'delete_tag',
    VIEW_FLEETS: 'view_fleets',
    CREATE_FLEET: 'create_fleet',
    EDIT_FLEET: 'edit_fleet',
    DELETE_FLEET: 'delete_fleet',
    VIEW_JOB_ORDERS: 'view_job_orders',
    CREATE_JOB_ORDER: 'create_job_order',
    EDIT_JOB_ORDER: 'edit_job_order',
    DELETE_JOB_ORDER: 'delete_job_order',
    VIEW_DISPATCH_ORDERS: 'view_dispatch_orders',
    EDIT_DISPATCH_ORDER: 'edit_dispatch_order',
    VIEW_CUSTOMERS: 'view_customers',
    CREATE_CUSTOMER: 'create_customer',
    EDIT_CUSTOMER: 'edit_customer',
    DELETE_CUSTOMER: 'delete_customer',
    VIEW_EMPLOYEES: 'view_employees',
    CREATE_EMPLOYEE: 'create_employee',
    EDIT_EMPLOYEE: 'edit_employee',
    DELETE_EMPLOYEE: 'delete_employee',
    VIEW_ACTIVITIES: 'view_activities',
    CREATE_ACTIVITY: 'create_activity',
    EDIT_ACTIVITY: 'edit_activity',
    DELETE_ACTIVITY: 'delete_activity',
    VIEW_NOTES: 'view_notes',
    CREATE_NOTE: 'create_note',
    EDIT_NOTE: 'edit_note',
    DELETE_NOTE: 'delete_note'
};

export default PermissionEnum;
