import React, { createContext, useContext, useState, useEffect } from 'react';
import { organizationService } from '../network/organization';
import { AppContext } from './AppContextProvider';
import { message } from 'antd';

export const OrganizationContext = createContext(null);

export const OrganizationProvider = ({ children }) => {
    const { deploymentMode, authUser } = useContext(AppContext);
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (deploymentMode === 'multi-tenant' && authUser) {
            fetchCurrentOrganization();
        } else {
            setLoading(false);
        }
    }, [deploymentMode, authUser]);

    const fetchCurrentOrganization = async () => {
        try {
            const response = await organizationService.getCurrent();
            if (response.data?.success) {
                setCurrentOrganization(response.data.organization);
            }
        } catch (error) {
            message.error('Failed to fetch organization details');
        } finally {
            setLoading(false);
        }
    };

    const updateOrganization = async (data) => {
        try {
            const response = await organizationService.update(currentOrganization.id, data);
            if (response.data?.success) {
                setCurrentOrganization(response.data.organization);
                return true;
            }
            return false;
        } catch (error) {
            message.error('Failed to update organization');
            return false;
        }
    };

    return (
        <OrganizationContext.Provider
            value={{
                currentOrganization,
                setCurrentOrganization,
                updateOrganization,
                loading
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export const useOrganization = () => useContext(OrganizationContext);
