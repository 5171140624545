import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRoutes } from '../hooks/useRoutes';
import RouteGuard from '../components/RouteGuard';

const AppRoutes = () => {
  const routes = useRoutes();

  const renderRoutes = (routes) => {
    return routes.map(route => {
      if (route.children) {
        return renderRoutes(route.children);
      }

      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <RouteGuard
              component={route.component}
              permission={route.permission}
              deploymentMode={route.deploymentMode}
            />
          }
        />
      );
    });
  };

  return (
    <Routes>
      {renderRoutes(routes)}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
